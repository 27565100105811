<template>
    <div class="container my-24 my-lg-40">
      <div class="row mb-24">
        <header class="col">
          <h2>Согласие на обработку персональных данных</h2>
        </header>
      </div>

      <section class="row">
        <article
          class="col-12"
        >
          <v-card class="p-16 py-md-32 p-lg-32">
            <p>
              Товарищи! дальнейшее развитие различных форм деятельности играет важную роль в формировании дальнейших направлений развития. Разнообразный и богатый опыт постоянное информационно-пропагандистское обеспечение нашей деятельности влечет за собой процесс внедрения и модернизации направлений прогрессивного развития. С другой стороны сложившаяся структура организации позволяет выполнять важные задания по разработке модели развития.
            </p>

            <p>
              С другой стороны постоянный количественный рост и сфера нашей активности позволяет выполнять важные задания по разработке направлений прогрессивного развития. Значимость этих проблем настолько очевидна, что реализация намеченных плановых заданий требуют определения и уточнения модели развития. С другой стороны сложившаяся структура организации способствует подготовки и реализации модели развития.
            </p>

            <p>
              Значимость этих проблем настолько очевидна, что укрепление и развитие структуры позволяет оценить значение системы обучения кадров, соответствует насущным потребностям. С другой стороны начало повседневной работы по формированию позиции позволяет оценить значение форм развития. Повседневная практика показывает, что рамки и место обучения кадров представляет собой интересный эксперимент проверки новых предложений. Таким образом консультация с широким активом позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач.
            </p>

            <hr class="my-24">

            <v-checkbox v-model="consent">
              Я даю согласие на обработку данных
            </v-checkbox>
          </v-card>
        </article>
      </section>

      <div class="row mt-32">
        <div class="col">
          <v-button
            :disabled="!consent"
          >
            Перейти к тесту
          </v-button>
        </div>
      </div>
    </div>
</template>

<script>
import VCard from '../../../components/base/VCard'
import VButton from '../../../components/base/VButton'
import VCheckbox from '../../../components/base/VCheckbox'

export default {
  name: 'SAgreement',

  components: {
    VCheckbox,
    VButton,
    VCard
  },

  data () {
    return {
      consent: false
    }
  }
}
</script>
